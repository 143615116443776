import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'

import ProjectsPageTemplate from '../../templates/projects-page'
import ProjectsSlidingPills from '../../components/ProjectsSlidingPills'

import Seo from '../../components/seo'
import Layout from '../../components/Layout'
import MatterComponent from '../../components/Matter'
import {
  FullWidthImage,
  HalfWidthImage,
  HalfWidthText,
  Testimonial,
  Mockup,
} from '../../components/projects'

import AWSCognitoSVG from '../../assets/logo/aws-cognito-logo.svg'
import AWSAuroraPostgresSVG from '../../assets/logo/aws-aurora-logo.svg'
import ReactSVG from '../../assets/logo/react-color-logo.svg'
import RailsSVG from '../../assets/logo/rails-color-logo.svg'
import TypescriptSVG from '../../assets/logo/typescript-logo.svg'

import { Headline3, Body1 } from '../../components/Typography'

import MeUndiesSVG from '../../assets/logo/meundies-logo.svg'
import RowanSVG from '../../assets/logo/rowan-logo.svg'

const teamImages = [
  '/images/chris.png',
  '/images/jared.png',
  '/images/adam.png',
]

const AWSCognito = props => {
  return (
    <AWSCognitoSVG
      {...props}
      style={{ display: 'block', height: '24px', width: '24px' }}
    />
  )
}

const AWSAuroraPostgres = props => {
  return (
    <AWSAuroraPostgresSVG
      {...props}
      style={{ display: 'block', height: '24px', width: '24px' }}
    />
  )
}

const ReactIcon = props => {
  return (
    <ReactSVG
      {...props}
      style={{ display: 'block', height: '24px', width: '24px' }}
    />
  )
}

const Rails = props => {
  return (
    <RailsSVG
      {...props}
      style={{ display: 'block', height: '24px', width: '24px' }}
    />
  )
}

const Typescript = props => {
  return (
    <TypescriptSVG
      {...props}
      style={{ display: 'block', height: '24px', width: '24px' }}
    />
  )
}

const meUndiesPillsFirstRow = [
  ['5', 'Developers'],
  ['8,549', 'Commits'],
  ['+329,057', 'Lines of code added'],
  ['1,841', 'Pull requests'],
  ['-249,272', 'Lines of code deleted'],
]

const meUndiesPillsSecondRow = [
  'Guest checkout',
  'Subscriptions',
  'WMS Integration',
  'Matching pairs',
  'Build-a-pack',
  'ERP Integration',
]

const meUndiesPillsThirdRow = [
  [<AWSCognito />, 'AWS Cognito'],
  [<AWSAuroraPostgres />, 'AWS Aurora Postgres'],
  [<Rails />, 'Ruby on Rails'],
  [<ReactIcon />, 'React'],
  [<Typescript />, 'Typescript'],
]

const MeUndiesLogo = props => {
  const StyledLogo = styled(MeUndiesSVG)`
    display: block;
    width: 189px;
    height: 36px;

    path {
      fill: var(--terminal-0);
    }
  `

  return <StyledLogo {...props} />
}

const RowanLogo = props => {
  const StyledLogo = styled(RowanSVG)`
    display: block;
    width: 189px;
    height: 36px;

    path {
      fill: var(--terminal-0);
    }
  `

  return <StyledLogo {...props} />
}

const OrangeLink = styled.a`
  ${Body1};
  color: var(--firewall-500);
  text-decoration: none;
  font-weight: bold;
  display: flex;
  justify-content: center;
`

const MatterPlaceholder = styled.div`
  background: var(--terminal-500);
  height: 100vh;
  width: 100vw;
`

const Title = styled.h2`
  ${Headline3};
  color: var(--terminal-0);
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: center;
`

function MeUndies() {
  const pageTitle = 'MeUndies'
  const [windowResizing, setWindowResizing] = useState(false)

  useEffect(() => {
    let timeout
    const cachedWidth = window.innerWidth

    const handleResize = () => {
      clearTimeout(timeout)

      if (cachedWidth !== window.innerWidth) {
        setWindowResizing(true)
      }

      timeout = setTimeout(() => {
        setWindowResizing(false)
      }, 200)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const pageQuery = useStaticQuery(graphql`
    query {
      jason: file(relativePath: { eq: "jason.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      meundiesHeader: file(relativePath: { eq: "meundies-header.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      meundiesFull: file(relativePath: { eq: "meundies-full.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      meundiesPortrait: file(relativePath: { eq: "meundies-portrait.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      meundiesMockup: file(relativePath: { eq: "meundies-mockup.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      rowanHeader: file(relativePath: { eq: "rowan-header.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  return (
    <ProjectsPageTemplate
      title={pageTitle}
      image={pageQuery.meundiesHeader.childImageSharp.gatsbyImageData}
      logo={<MeUndiesLogo />}
      nextimage={pageQuery.rowanHeader.childImageSharp.gatsbyImageData}
      nextlogo={<RowanLogo />}
      nextpath={'/work/rowan'}
      heading="We integrated with the MeUndies team for a long-term, committed relationship, touching all parts of the site and optimizing everything top to bottom."
      copyrightYear={'2022'}
    >
      <Seo
        title={pageTitle}
        keywords={[
          `Ruby on Rails`,
          `Rails`,
          `React`,
          `Spree`,
          `Solidus`,
          `eCommerce`,
        ]}
      />
      <ProjectsSlidingPills
        firstrow={meUndiesPillsFirstRow}
        secondrow={meUndiesPillsSecondRow}
        thirdrow={meUndiesPillsThirdRow}
      />
      <OrangeLink href="https://www.meundies.com/" rel="nofollow">
        Visit MeUndies →
      </OrangeLink>
      <Layout>
        <FullWidthImage
          image={pageQuery.meundiesFull.childImageSharp.gatsbyImageData}
        />
        <HalfWidthText
          title="Overline"
          body="MeUndies were the first innovators in the multi-billion dollar underwear industry to launch a DTC subscription model, pairing quality and comfort with innovative customizations and unique interactive shopping experiences. With no shortage of fabrics, fits, and clever designs, MeUndies outfits underwear for everyone."
        />
        <HalfWidthImage
          image={pageQuery.meundiesPortrait.childImageSharp.gatsbyImageData}
        />
        <Testimonial
          image={pageQuery.jason.childImageSharp.gatsbyImageData}
          quotation="As a core part of the MeUndies engineering team, Super Good's contributions have been invaluable to the growth and success of our brand. Super Good truly understands our business and uses that insight to build a stronger membership platform and commerce experience for our customers."
          name="Jason Matthews"
          title="Director of Engineering, MeUndies"
        />
        <Mockup
          image={pageQuery.meundiesMockup.childImageSharp.gatsbyImageData}
        />
      </Layout>
      <Title>Contributors</Title>
      {windowResizing ? (
        <MatterPlaceholder />
      ) : (
        <MatterComponent images={teamImages} />
      )}
    </ProjectsPageTemplate>
  )
}

export default MeUndies
